'use client';

import { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import { Button, Popup } from '@zealy/design-system';
import { useSubmitNPS } from '@zealy/queries';

import { usePopup } from '#features/Popups/PopupProvider';

import { useShouldShowNPS } from './NPS.hooks';
import { NPSForm } from './NPSForm';

export const NPSPopup = () => {
  const t = useTranslations();
  const { isOpen, onOpenChange } = usePopup({
    id: 'nps',
    priority: 2,
  });

  const { subdomain } = useParams<{ subdomain: string }>();

  const submitNPS = useSubmitNPS('me');

  const showPopup = useShouldShowNPS();

  useEffect(() => {
    if (showPopup) {
      onOpenChange(true);
    }
    return () => onOpenChange(false);
  }, [onOpenChange, showPopup]);

  const handleSubmit = (score: number, suggestion: string) => {
    submitNPS.mutate({ score, suggestion, subdomain });
  };

  return (
    <Popup
      open={isOpen}
      className="w-fit max-w-full bg-popover-secondary"
      hideCloseButton
      contentProps={{
        onPointerDownOutside: e => {
          if (submitNPS.status === 'success') {
            onOpenChange(false);
          }
        },
      }}
    >
      {submitNPS.status === 'success' || submitNPS.status === 'error' ? (
        <div className="flex flex-col gap-200">
          <div className="h-800 w-800 bg-contain bg-no-repeat bg-center mx-auto bg-mascot-happy" />

          <p className="body-component-lg-bold text-primary">{t('ds.feedback.thanks')}</p>
          <Button onClick={() => onOpenChange(false)} variant="muted" className="self-end">
            {t('common.close')}
          </Button>
        </div>
      ) : (
        <NPSForm onSubmit={handleSubmit} />
      )}
    </Popup>
  );
};
