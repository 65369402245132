import { useSessionStorageValue } from '@react-hookz/web';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useParams, usePathname } from 'next/navigation';

import type { Community, CommunityMember } from '@zealy/queries';
import {
  useAuthenticatedUser,
  useCurrentSprint,
  useFailedAutomations,
  useInbox,
  useListPartnerships,
  usePendingReviewsCount,
} from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { useCommunitiesFlags } from '#context/FeatureFlags';

import type { BaseSidebarItem } from './Sidebar.constants';
import { BASE_SIDEBAR_ITEMS } from './Sidebar.constants';

const useUnreadNotifications = ({ enabled }: { enabled: boolean }) => {
  const { data: user } = useAuthenticatedUser<CommunityMember>();

  const discordAlerts = useFailedAutomations(undefined, {
    enabled: roleIsAtLeast(user?.role, 'admin'),
  });

  const inbox = useInbox(undefined, undefined, undefined, enabled);

  let unreadNotifications = 0;

  if (discordAlerts.data?.missingPermissionCount) unreadNotifications += 1;

  if (inbox.data?.pages?.[0]?.totalUnread)
    unreadNotifications += inbox.data?.pages?.[0]?.totalUnread;

  return unreadNotifications;
};

const usePendingReviews = ({ enabled, subdomain }: { enabled: boolean; subdomain: string }) => {
  const oldReviews = usePendingReviewsCount({
    enabled,
  }).data;

  return oldReviews;
};

const communityHasLaunched = (community: Community) =>
  !!community?.launchDate && new Date(community?.launchDate) < new Date();

const useShowSidebarItem = (community: Community, user?: CommunityMember) => {
  const { analytics, crm } = useCommunitiesFlags(['crm', 'analytics']);
  const isTooRecent = dayjs(community.createdAt).isAfter(dayjs().subtract(3, 'day'));

  return (item: BaseSidebarItem) => {
    if (item.role && !roleIsAtLeast(user?.role, item.role)) return false;

    if (item.value === 'analytics' && isTooRecent && !analytics.enabled) return false;

    if (item.value === 'members' && isTooRecent && !crm.enabled) return false;

    return true;
  };
};

const useSidebarBadge = (community: Community, user?: CommunityMember) => {
  const hasLaunched = communityHasLaunched(community);
  const incomingPartnershipsQuery = useListPartnerships({
    subdomain: community.subdomain,
    query: { status: 'pending', position: 'incoming' },
  });

  const badges = {
    notifications: useUnreadNotifications({
      enabled: roleIsAtLeast(user?.role, 'guest') && hasLaunched,
    }),

    reviews: usePendingReviews({
      enabled: roleIsAtLeast(user?.role, 'reviewer'),
      subdomain: community.subdomain,
    }),

    partnerships: incomingPartnershipsQuery.data?.length,
  } as const;

  return (item: BaseSidebarItem) => {
    if (item.value in badges) {
      const badge = badges[item.value as keyof typeof badges];

      return !!badge && badge > 99 ? '99+' : badge;
    }
  };
};

const getURL = (
  community: Community,
  user: CommunityMember | undefined,
  value: string,
  childValue = '',
) => {
  if (value === 'questboard' && community?.v2) {
    return `/cw/${community.subdomain}/questboard/${
      roleIsAtLeast(user?.role, 'editor') ? 'admin' : childValue === 'general' ? '' : childValue
    }`;
  }
  if (value === 'questboard') {
    return `/c/${community.subdomain}/questboard?view=${childValue}`;
  }

  if (value === 'analytics') {
    return `/cw/${community.subdomain}/analytics`;
  }

  if (value === 'partnerships') {
    return `/cw/${community.subdomain}/partnerships`;
  }

  return `/cw/${community.subdomain}/${value ?? ''}`;
};

const useSprintItems = (community: Community, user?: CommunityMember) => {
  const { value: sprintView, set: setSprintView } = useSessionStorageValue<boolean>(
    `sprintView:${community.subdomain}`,
  );

  const { sprintId } = useParams<{ sprintId?: string }>();

  const t = useTranslations('sidebar');
  const hasLaunched = communityHasLaunched(community);
  const currentSprint = useCurrentSprint(hasLaunched && user?.role !== 'banned');
  const hasSprint = !!currentSprint.data;

  return (item: BaseSidebarItem, selected: boolean) => {
    if (!hasSprint) return {};

    const baseUrl = getURL(community, user, item.value);

    if (item.value === 'questboard' && !roleIsAtLeast(user?.role, 'editor')) {
      const isSprintView = !!sprintView;

      return {
        children: [
          {
            value: 'general',
            href: getURL(community, user, item.value, 'general'),
            selected: selected && !isSprintView,
            label: t(`items.general`),
            className: 'w-full',
            onClick: () => setSprintView(false),
          },
          {
            value: 'sprints',
            href: getURL(community, user, item.value, 'sprints'),
            selected: selected && isSprintView,
            label: t(`items.sprints`),
            className: 'w-full',
            onClick: () => setSprintView(true),
          },
        ],
        expandable: true,
      };
    }

    if (item.value === 'leaderboard')
      return {
        children: [
          {
            value: 'all-time',
            href: baseUrl,
            selected: selected && !sprintId,
            label: t(`items.all-time`),
            className: 'w-full',
          },
          {
            value: 'sprints',
            href: `${baseUrl}/${currentSprint.data?.id}`,
            selected: selected && !!sprintId,
            label: t(`items.sprints`),
            className: 'w-full',
          },
        ],
        expandable: true,
      };

    return {};
  };
};

const itemIsDisabled = (
  community: Community,
  user: CommunityMember | undefined,
  item: BaseSidebarItem,
) =>
  !communityHasLaunched(community) &&
  !roleIsAtLeast(user?.role, 'reviewer') &&
  item.value !== 'questboard';

export function useSidebar({ user, community }: { user?: CommunityMember; community: Community }) {
  const t = useTranslations('sidebar');
  const sidebarFilter = useShowSidebarItem(community, user);
  const badges = useSidebarBadge(community, user);
  const sprintItems = useSprintItems(community, user);

  const pathname = usePathname();
  const currentTab = useMemo(() => {
    const [, match] = /\/c[w]?\/[\w-]+\/([\w-]+)/.exec(pathname) ?? [];
    return match;
  }, [pathname]);

  return BASE_SIDEBAR_ITEMS.filter(sidebarFilter).map(item => ({
    ...item,
    ...sprintItems(item, currentTab === item.value),
    badge: badges(item),
    href: getURL(community, user, item.value),
    selected: currentTab === item.value,
    label: t(`items.${item.value}`),
    className: 'w-full',
    disabled: itemIsDisabled(community, user, item) || user?.role === 'banned',
  }));
}
