import type { ReactElement } from 'react';
import React from 'react';

import type { UserRole } from '@zealy/utils';
import {
  ChartPieFilled,
  GamesFilled,
  InboxFilled,
  MapViewFilled,
  PodiumFilled,
  RefreshCcwLine,
  ThumbsUpFilled,
  UsersFilled,
} from '@zealy/icons';

export const SIDEBAR_ICONS_LOOKUP = {
  questboard: <MapViewFilled />,
  sprints: undefined,
  general: undefined,
  'all-time': undefined,
  reviews: <ThumbsUpFilled />,
  leaderboard: <PodiumFilled />,
  notifications: <InboxFilled />,
  members: <UsersFilled />,
  analytics: <ChartPieFilled />,
  automations: <RefreshCcwLine />,
  partnerships: <GamesFilled />,
} as const;

export type BaseSidebarItem = {
  value:
    | 'questboard'
    | 'sprints'
    | 'general'
    | 'all-time'
    | 'content'
    | 'reviews'
    | 'leaderboard'
    | 'notifications'
    | 'members'
    | 'analytics'
    | 'partnerships';
  leftIcon?: ReactElement;
  badge?: string | number;
  role?: UserRole;
  disabled?: boolean;
  size?: 'sm' | 'md';
};

export const BASE_SIDEBAR_ITEMS: BaseSidebarItem[] = [
  {
    value: 'questboard',
    leftIcon: SIDEBAR_ICONS_LOOKUP['questboard'],
  },
  {
    value: 'leaderboard',
    leftIcon: SIDEBAR_ICONS_LOOKUP['leaderboard'],
  },
  {
    value: 'notifications',
    leftIcon: SIDEBAR_ICONS_LOOKUP['notifications'],
    role: 'guest',
  },
  {
    value: 'reviews',
    leftIcon: SIDEBAR_ICONS_LOOKUP['reviews'],
    role: 'reviewer',
  },
  {
    value: 'members',
    leftIcon: SIDEBAR_ICONS_LOOKUP['members'],
    role: 'editor',
  },
  {
    value: 'analytics',
    leftIcon: SIDEBAR_ICONS_LOOKUP['analytics'],
    role: 'editor',
  },
  {
    value: 'partnerships',
    leftIcon: SIDEBAR_ICONS_LOOKUP['partnerships'],
    role: 'editor',
  },
];
