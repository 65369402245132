'use client';

import React, { useEffect } from 'react';
import { useCookie } from 'react-use';
import { useIntercom } from 'react-use-intercom';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';

import { AlertDialogContent, AlertDialogRoot } from '@zealy/design-system';
import { CommunityMember, useAuthenticatedUser, useCommunity } from '@zealy/queries';
import { getSubdomain, roleIsAtLeast } from '@zealy/utils';

import { usePopup } from './PopupProvider';

export const FlaggedCommunityAlertDialog = () => {
  const { isOpen, onOpenChange } = usePopup({
    id: 'flagged_warning',
    priority: 1,
  });

  const t = useTranslations('flagged-community-warning');
  const community = useCommunity();
  const [cookie, setCookie] = useCookie('community-flag');
  const router = useRouter();
  const { show } = useIntercom();

  const user = useAuthenticatedUser<CommunityMember>();

  const handleClose = () => {
    setCookie('true', { expires: 7, path: `/cw/${getSubdomain()}` });
    onOpenChange(false);
  };

  const redirectHome = () => {
    onOpenChange(false);
    router.push('/');
  };

  const openSupportChat = () => {
    setCookie('true', { expires: 7, path: `/cw/${getSubdomain()}` });
    show();
    onOpenChange(false);
  };

  const open = !cookie && community.data?.flag;

  const role = roleIsAtLeast(user.data?.role, 'editor') ? 'admin' : 'non-admin';

  useEffect(() => {
    if (open) {
      onOpenChange(true);
    }
    return () => onOpenChange(false);
  }, [open, onOpenChange]);

  return (
    <AlertDialogRoot open={isOpen}>
      <AlertDialogContent
        title={t(`${role}.title`)}
        description={t(`${role}.description`)}
        confirmLabel={role === 'admin' ? t(`${role}.contact-support`) : t(`${role}.confirm`)}
        cancelLabel={role === 'admin' ? t(`${role}.close`) : t(`${role}.cancel`)}
        onCancel={role === 'admin' ? handleClose : redirectHome}
        onConfirm={role === 'admin' ? openSupportChat : handleClose}
      />
    </AlertDialogRoot>
  );
};
