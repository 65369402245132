'use client';

import type { HTMLAttributes } from 'react';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/navigation';

import type { Community } from '@zealy/queries';
import { CommunityBar as CommunityBarComponent } from '@zealy/design-system';
import { useReorderUserCommunities, useUserCommunities } from '@zealy/queries';
import { getCommunityPath } from '@zealy/utils';

import { useDidMount } from '#hooks/useDidMount';

export const CommunityBar = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const didMount = useDidMount();
  const [search, setSearch] = useState('');
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } = useUserCommunities(search);
  const [communities, setCommunities] = useState(data?.pages?.flatMap(page => page.results || []));
  const [initialLoad, setInitialLoad] = useState(true);

  const reorderCommunities = useReorderUserCommunities();

  useEffect(() => {
    setCommunities(data?.pages?.flatMap(page => page.results || []));
  }, [data]);

  const router = useRouter();

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;

    if (bottom && !isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  const handleReorder = (newOrder: typeof communities) => {
    if (!search && newOrder) setCommunities(newOrder);
  };

  const debouncedSearch = debounce((value: string) => {
    setSearch(value);
  }, 500);

  useDebounce(
    () => {
      if (!communities || initialLoad) return setInitialLoad(false);

      reorderCommunities.mutate(communities.map(({ id }) => id));
    },
    1000,
    [communities],
  );

  return (
    <CommunityBarComponent
      className={className}
      onScroll={handleScroll}
      communities={communities ?? []}
      onNavigate={(community: Community) => router.push(getCommunityPath(community))}
      onReorder={handleReorder}
      onSearchChange={debouncedSearch}
      loading={isLoading || !didMount}
    />
  );
};
