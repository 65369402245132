'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Reorder } from 'framer-motion';
import { useLongPress } from 'use-long-press';
import { ChevronLeftLine, GlobeLine, PlusLine, SearchLine, ZealyBrand, ZealyFullBrand, } from '@zealy/icons';
import { canUseDOM, getSubdomain } from '@zealy/utils';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { cn } from '../../utils/cn';
import { Button } from '../Button';
import { Input } from '../TextField';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Tooltip';
import { CommunityAvatar } from './CommunityAvatar';
import { t } from './CommunityBar.constants';
import { styles } from './CommunityBar.styles';
export const CommunityBar = ({ className, communities, onReorder, onNavigate, onSearchChange, loading, ...props }) => {
    const ref = useRef(null);
    const [state, setState] = useState();
    const [search, setSearch] = useState('');
    const selectedSubdomain = getSubdomain();
    const coarsePointer = canUseDOM() && window?.matchMedia?.('(pointer: coarse)').matches;
    const [reorderMode, setReorderMode] = useState(!coarsePointer);
    const bind = useLongPress(() => {
        setReorderMode(true);
    });
    const toggleOpen = () => {
        if (state === 'open') {
            setSearch('');
            onSearchChange('');
        }
        setState(state === 'open' ? 'closed' : 'open');
    };
    const handleSelectCommunity = (community) => () => {
        if (community.subdomain === selectedSubdomain)
            return;
        onNavigate(community);
    };
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        onSearchChange(e.target.value);
    };
    useOnClickOutside(ref, () => {
        if (coarsePointer)
            setReorderMode(false);
        if (state === 'open')
            toggleOpen();
    });
    return (_jsx("div", { className: cn(styles.root, className), children: _jsxs(Reorder.Group, { axis: "y", onReorder: onReorder, values: communities, ref: ref, className: cn(styles.bar, className), "data-state": state, layoutScroll: true, "data-testid": "community-list", ...props, children: [_jsx("a", { href: "/", className: "p-100", children: state === 'open' ? (_jsx(ZealyFullBrand, { width: 102, height: 24 })) : (_jsx(ZealyBrand, { width: 24, height: 24 })) }), _jsxs(TooltipProvider, { children: [state === 'open' ? (_jsx(Input, { name: "search", type: "search", leftIcon: _jsx(ChevronLeftLine, { onClick: toggleOpen }), placeholder: t.search.placeholder, autoFocus: true, className: cn(styles.search, className), value: search, onChange: handleSearchChange, autoComplete: "off" })) : (_jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { asChild: true, children: _jsx(Button, { variant: "muted", onlyIcon: true, onClick: toggleOpen, leftIcon: _jsx(SearchLine, {}), className: "group-data-[state=open]/bar:hidden", "data-testid": "search-button" }) }), _jsx(TooltipContent, { side: "right", className: "flex flex-col gap-tooltip-container-y", children: _jsx("p", { children: t.search.placeholder }) })] })), communities?.map(community => reorderMode ? (_jsx(Reorder.Item, { value: community, children: _jsx(CommunityAvatar, { name: community.name,
                                src: community.image,
                                onClick: handleSelectCommunity(community),
                                selected: selectedSubdomain === community.subdomain,
                                className: coarsePointer ? 'animate-wiggle' : undefined }) }, community.id)) : (_jsx(CommunityAvatar, { name: community.name,
                            src: community.image,
                            onClick: handleSelectCommunity(community),
                            selected: selectedSubdomain === community.subdomain,
                            ...bind() }, community.id))), loading && (_jsxs(_Fragment, { children: [_jsx(CommunityAvatar.Skeleton, {}, 0), _jsx(CommunityAvatar.Skeleton, {}, 1), _jsx(CommunityAvatar.Skeleton, {}, 2)] })), search && !loading && !communities?.length && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col text-center gap-200 items-center py-200", children: [_jsx(SearchLine, { size: 24 }), _jsxs("div", { className: "flex flex-col gap-100", children: [_jsxs("p", { className: "text-primary body-paragraph-md-bold", children: [t.search['no-results'].title, " '", search, "'"] }), _jsx("p", { className: "text-secondary body-paragraph-md", children: t.search['no-results'].description })] })] }), _jsx(Button, { variant: "muted", as: "a", href: `/search?q=${search}`, className: "w-fit mx-auto", children: t.search['no-results'].cta })] })), !search && (_jsxs(_Fragment, { children: [_jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { asChild: true, children: _jsx(Button, { variant: "muted", onlyIcon: state !== 'open', leftIcon: _jsx(PlusLine, {}), as: "a", href: "/create-community", target: "_blank", children: t['create-community'] }) }), _jsx(TooltipContent, { side: "right", children: t['create-community'] })] }), _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { asChild: true, children: _jsx(Button, { variant: "muted", onlyIcon: state !== 'open', leftIcon: _jsx(GlobeLine, {}), as: "a", href: "/explore", target: "_blank", children: t['explore'] }) }), _jsx(TooltipContent, { side: "right", children: t['explore'] })] })] }))] })] }) }));
};
