'use client';

import React, { Fragment } from 'react';
import Link from 'next/link';

import type { Community, CommunityMember } from '@zealy/queries';
import {
  Button,
  DropdownContent,
  DropdownGroup,
  DropdownItem,
  DropdownMenu,
  DropdownPortal,
  DropdownSeparator,
  DropdownTrigger,
} from '@zealy/design-system';
import { GearLine, InfoLine } from '@zealy/icons';

import { useCommunityActions } from './CommunityActions.hooks';

const LinkWrapper = ({ children, href }: { children: React.ReactNode; href?: string | null }) =>
  href ? (
    <Link href={href} prefetch={false} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  ) : (
    children
  );

export function CommunityDropdown({
  community,
  user,
}: {
  community: Community;
  user?: CommunityMember;
}) {
  const actions = useCommunityActions({ community, user });

  return (
    <DropdownMenu name="community-dropdown" size="sm">
      <DropdownTrigger asChild>
        <Button
          size="sm"
          variant={'muted'}
          onlyIcon
          leftIcon={user?.role === 'admin' ? <GearLine /> : <InfoLine />}
          data-testid="community-menu-trigger"
          className="backdrop-blur-sm"
        />
      </DropdownTrigger>
      <DropdownPortal>
        <DropdownContent
          className="min-w-[200px] z-50"
          sideOffset={16}
          alignOffset={0}
          align="end"
          data-testid="community-menu"
        >
          {actions.map((group, index) =>
            group.items.length > 0 ? (
              <Fragment key={group.key}>
                {index > 0 ? <DropdownSeparator /> : null}
                <DropdownGroup>
                  {group.items.map(item => (
                    <LinkWrapper href={item.link} key={item.label}>
                      <DropdownItem
                        key={item.label}
                        leftIcon={item.icon}
                        onClick={item.onClick}
                        disabled={item.disabled}
                        type={item.type}
                      >
                        {item.label}
                      </DropdownItem>
                    </LinkWrapper>
                  ))}
                </DropdownGroup>
              </Fragment>
            ) : null,
          )}
        </DropdownContent>
      </DropdownPortal>
    </DropdownMenu>
  );
}
